import _, { get, isEmpty, map, mapValues, some } from 'lodash';
import fp from 'lodash/fp';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { COLORS, FlexColumn, Header, Text } from '@summer/ui-components';

import { ConsentToIrsDisclosure } from 'components/pages/idr/wizard/steps/Submit/fields';
import PayFrequency from 'components/pages/idr/wizard/steps/Submit/sections/PayFrequency';
import {
  IncomeHelpText,
  UploadHelpText,
} from 'components/pages/idr/wizard/steps/Submit/sections/UploadDocuments/helpers';
import SubmitHeader from 'components/pages/idr/wizard/steps/Submit/shared/Header';
import FilesUpload from 'components/shared/FilesUpload/FilesUpload';
import InfoTooltip from 'components/shared/InfoTooltip';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import {
  clearFilesErrors,
  deleteFile,
  uploadFileV4,
} from 'redux/actions/fileCollections.actions';
import {
  getCurrentIdrFormId,
  getRequiredSupportingDocuments,
} from 'redux/selectors/idr.selectors';
import { openChat } from 'services/chat';

const filterValidFiles = _.flow(
  fp.filter({ uploaded: true }),
  fp.reject({ error: true }),
);

const getValidFilesForEachDocumentType = requiredSupportingDocuments => {
  return mapValues(requiredSupportingDocuments, filterValidFiles);
};

const isUserMissingRequireFiles = requiredSupportingDocuments => {
  return some(
    getValidFilesForEachDocumentType(requiredSupportingDocuments),
    isEmpty,
  );
};

export const isRequiredFileMissing = (
  requiredSupportingDocuments,
  collectionName,
) => {
  const validFiles = getValidFilesForEachDocumentType(
    requiredSupportingDocuments,
  );
  return isEmpty(validFiles[collectionName]);
};

class UploadDocuments extends Component {
  componentDidMount() {
    const {
      idrHandlers: { setFieldValue },
      requiredSupportingDocuments,
    } = this.props;

    // yotav: hack fix until i get answer from formik
    setTimeout(() => {
      setFieldValue(
        'supportingDocuments.isValid',
        !isUserMissingRequireFiles(requiredSupportingDocuments),
      );
      if (!get(requiredSupportingDocuments, 'userProofOfIncome')) {
        setFieldValue('submit.payFrequency', null);
      }

      if (!get(requiredSupportingDocuments, 'spouseProofOfIncome')) {
        setFieldValue('submit.spousePayFrequency', null);
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      idrHandlers: { setFieldValue },
      requiredSupportingDocuments,
    } = this.props;
    if (prevProps.requiredSupportingDocuments !== requiredSupportingDocuments) {
      setFieldValue(
        'supportingDocuments.isValid',
        !isUserMissingRequireFiles(requiredSupportingDocuments),
      );
    }
  }

  handleFileUpload = collection => file => {
    const { formId } = this.props;
    this.props.uploadFileV4({ collection, file, formId });
  };

  handleDeleteUpload = collection => fileId => {
    this.props.deleteFile({ collection, fileId });
  };

  renderCollectionNameToHeader = {
    userTaxReturn: (
      <div>
        <SupportingDocumentHeader>
          Your federal tax return
        </SupportingDocumentHeader>
        <UploadHelpText />
      </div>
    ),
    spouseTaxReturn: (
      <div>
        <SupportingDocumentHeader>
          Your spouse’s federal tax return
        </SupportingDocumentHeader>
        <UploadHelpText />
      </div>
    ),
    jointTaxReturn: (
      <div>
        <SupportingDocumentHeader>
          Your joint federal tax return
        </SupportingDocumentHeader>
        <UploadHelpText />
      </div>
    ),
    userProofOfIncome: (
      <div>
        <SupportingDocumentHeader>
          Your proof of income
          <InfoTooltip
            top={2}
            width={480}
            text={
              <>
                Provide documentation of all taxable income (e.g., employment,
                unemployment, dividends, interest, tips, and alimony). You do
                not need to include untaxed income like Supplemental Security
                Income, child support, or federal/state public assistance
              </>
            }
          />
        </SupportingDocumentHeader>
        <IncomeHelpText />
      </div>
    ),

    spouseProofOfIncome: (
      <div>
        <SupportingDocumentHeader>
          Your spouse’s proof of income
          <InfoTooltip
            top={2}
            width={480}
            text={
              <>
                Provide documentation of all taxable income (e.g., employment,
                unemployment, dividends, interest, tips, and alimony). You do
                not need to include untaxed income like Supplemental Security
                Income, child support, or federal/state public assistance
              </>
            }
          />
        </SupportingDocumentHeader>
        <IncomeHelpText />
      </div>
    ),
  };

  renderRequiredSupportingDocuments = () => {
    const {
      requiredSupportingDocuments,
      clearFilesErrors,
      values,
      errors,
      touched,
      idrHandlers,
      currentStepSubmitCount,
    } = this.props;

    return map(requiredSupportingDocuments, (fileList, collectionName) => {
      const fileIsMissing = isRequiredFileMissing(
        requiredSupportingDocuments,
        collectionName,
      );
      const showError = fileIsMissing && currentStepSubmitCount > 0;

      return (
        <React.Fragment key={collectionName}>
          <SupportingDocument id={collectionName}>
            {this.renderCollectionNameToHeader[collectionName]}
            <FilesUpload
              clearFilesErrors={clearFilesErrors}
              handleFileUpload={this.handleFileUpload(collectionName)}
              handleDeleteFile={this.handleDeleteUpload(collectionName)}
              fileList={fileList}
              collectionName={collectionName}
            />
            <ErrorContainer>
              {showError && (
                <Text small color="error">
                  Please upload the required document before proceeding.
                </Text>
              )}
            </ErrorContainer>
            {(collectionName === 'userProofOfIncome' ||
              collectionName === 'spouseProofOfIncome') && (
              <>
                <Divider />
                <PayFrequency
                  values={values}
                  errors={errors || {}}
                  touched={touched || {}}
                  idrHandlers={idrHandlers}
                  userType={collectionName}
                />
              </>
            )}
          </SupportingDocument>
        </React.Fragment>
      );
    });
  };

  render() {
    const {
      requiredSupportingDocuments,
      values,
      errors,
      touched,
      idrHandlers,
      currentStepSubmitCount,
    } = this.props;

    const DocumentsNotRequiredCopy =
      'We don&rsquo;t need any documents at this time. Just answer the question below.';

    return (
      <>
        <SubmitHeader>Upload supporting documents</SubmitHeader>

        {isEmpty(requiredSupportingDocuments) ? (
          <Container>
            <SubHeader paragraph>{DocumentsNotRequiredCopy}</SubHeader>
          </Container>
        ) : (
          <>
            <HelperText>
              <Text paragraph weight="medium">
                <span role="img" aria-label="emoji">
                  📸
                </span>
                &nbsp;&nbsp; Fast track: You can take pictures of these
                documents on your phone, then upload! <br />
                Having trouble finding the right docs?{' '}
                <Link underline inheritColor inheritWeight onClick={openChat}>
                  Message us
                </Link>
                .
              </Text>
            </HelperText>
            <Container>{this.renderRequiredSupportingDocuments()}</Container>
          </>
        )}

        <ConsentToIrsDisclosure
          values={values}
          errors={errors || {}}
          touched={touched || {}}
          idrHandlers={idrHandlers}
          currentStepSubmitCount={currentStepSubmitCount}
        />
      </>
    );
  }
}

const Container = styled.div`
  @media (max-width: ${MobileSize}) {
    padding: 0 8px;
  }
`;

const SubHeader = styled(Text)`
  margin-bottom: 32px;
`;

const HelperText = styled.div`
  border-radius: 3px;
  padding: 16px;
  background-color: ${COLORS.lighterGrey};
  margin-bottom: 32px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 16px;
  }
`;

const dividerCss = css`
  content: '';
  background-color: ${COLORS.grey};
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 40px;
`;

const Divider = styled.div`
  ${dividerCss}
`;

const SupportingDocument = styled(FlexColumn)`
  &:not(:last-child):after {
    ${dividerCss}
  }
`;

const SupportingDocumentHeader = ({ children }) => (
  <SupportingDocumentHeaderContainer>
    <Header h4>{children}</Header>
  </SupportingDocumentHeaderContainer>
);
const SupportingDocumentHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const ErrorContainer = styled.div`
  min-height: 36px;
  margin-top: 16px;
`;

const mapStateToProps = state => ({
  requiredSupportingDocuments: getRequiredSupportingDocuments(state),
  formId: getCurrentIdrFormId(state),
});

export default connect(mapStateToProps, {
  deleteFile,
  uploadFileV4,
  clearFilesErrors,
})(UploadDocuments);
