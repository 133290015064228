import React from 'react';
import styled from 'styled-components';

import {
  COLORS,
  Header,
  ResponsiveColumn,
  Row,
  Text,
} from '@summer/ui-components';
import Link from '@summer/ui-components/src/core/typography/Link';

import { marketingAddress, supportAddress } from 'constants/emailLinks';
import { SmallDesktopSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { getPolicyLink } from 'utils/tuitionReimbursement';

const getHelperText = isTuitionAssistance => {
  if (isTuitionAssistance) {
    return (
      <>
        If you are having trouble creating or managing your Tuition Assistance
        requests, please email{' '}
        <Link href={`mailto:${supportAddress}`}>{supportAddress}</Link> and
        someone on the Summer team will make sure to help you.
      </>
    );
  }

  return (
    <>
      If you are having trouble creating or managing your tuition reimbursement
      requests, please email{' '}
      <Link href={`mailto:${marketingAddress}`}>{marketingAddress}</Link> and
      someone from the Summer team will be sure to help you.
    </>
  );
};

const HelpFooter = ({ isTuitionAssistance }) => {
  const { isMobile } = usePlatform();
  const policyLink = getPolicyLink();

  return (
    <HelpFooterRow>
      <ResponsiveColumn mobileCol="12" tabletCol="12" desktopCol="12">
        <HelpFooterContainer>
          {!isMobile && (
            <HelpFooterTitle>
              <Header as="h3" color="secondary">
                ASK US ANYTHING
              </Header>
            </HelpFooterTitle>
          )}
          <Text paragraph align="left">
            <EligibiltyText paragraph>
              Read through your employer&rsquo;s eligibility guide{' '}
              <Link href={policyLink}>here</Link>
            </EligibiltyText>
            {getHelperText(isTuitionAssistance)}
          </Text>
        </HelpFooterContainer>
      </ResponsiveColumn>
    </HelpFooterRow>
  );
};

const HelpFooterRow = styled(Row)`
  @media (max-width: ${SmallDesktopSize}) {
    margin-left: 0px;
  }

  margin-bottom: 48px;
`;

const HelpFooterContainer = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

const HelpFooterTitle = styled(Header)`
  height: 90px;
  border-right: solid 2px ${COLORS.grey};
  text-align: right;
  padding-top: 15px;
  padding-right: 25px;
  align-items: center;
`;

const EligibiltyText = styled(Text)`
  margin-bottom: 12px;
`;

export default HelpFooter;
