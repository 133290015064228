import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
  Button,
  COLORS,
  FlexRow,
  Header,
  SvgImage,
  Text,
  TuitionReimbursement,
} from '@summer/ui-components';
import Link from '@summer/ui-components/src/core/typography/Link';

import TuitionReimbursementLandingPage from 'components/pages/tuitionReimbursement/dashboard/shared/tuitionReimbursementLandingPage';
import { MobileSize } from 'constants/styleguide';
import { Apostrophes } from 'constants/text';
import { trackingEvents } from 'constants/trackingEvents';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  getIsTuitionAssistance,
  getTuitionReimbursementServiceType,
} from 'redux/selectors/tuitionReimbursement.selectors';
import { hexToRgba } from 'utils/common';
import { getPolicyLink } from 'utils/tuitionReimbursement';

const getTuitionReimbursementInstructions = () => {
  const policyLink = getPolicyLink();

  return [
    {
      title: 'Submit your education request',
      description: (
        <>
          Let your employer know which course you would like to take. Read
          through your employer&rsquo;s eligibility guide{' '}
          <Link href={policyLink}>here</Link> to ensure you submit all necessary
          documents before starting your course.
        </>
      ),
    },
    {
      title: 'Complete your course',
      description:
        'Once your course request is approved, you can proceed with your course.',
    },
    {
      title: 'Submit course completion proof',
      description:
        'Submit proof that you completed the course along with a receipt and your employer will reimburse you.',
    },
  ];
};

const getTuitionAssistanceInstructions = () => [
  {
    title: 'Submit your education request',
    description: `Let your employer know which course you would like to take. Don${Apostrophes}t forget! Review your employer${Apostrophes}s eligibility requirements to ensure you submit all necessary documents before starting your course.`,
  },
  {
    title: 'Complete your course',
    description:
      'Once your request is approved, you will receive tuition assistance from your employer so you can proceed with your course.',
  },
  {
    title: 'Submit course completion proof',
    description:
      'Submit proof that you paid for and completed the course to finish the process.',
  },
];

const TuitionReimbursementInstructions = () => {
  const isTuitionAssistance = useSelector(getIsTuitionAssistance);
  const instructions = isTuitionAssistance
    ? getTuitionAssistanceInstructions()
    : getTuitionReimbursementInstructions();

  return (
    <>
      <Header as="h1" bold>
        Get your education expenses covered
      </Header>
      <RoundedOrderedList>
        {instructions.map(instruction => (
          <OrderedListItem key={instruction.title}>
            <OrderedListTitle paragraph bold>
              {instruction.title}
            </OrderedListTitle>
            <Text paragraph>{instruction.description}</Text>
          </OrderedListItem>
        ))}
      </RoundedOrderedList>
    </>
  );
};

const StartPage = () => {
  const dispatch = useDispatch();
  const serviceType = useSelector(getTuitionReimbursementServiceType);

  const handleGetStarted = React.useCallback(() => {
    dispatch(
      trackServerSide(
        trackingEvents.EDU_ASSIST[serviceType].GET_STARTED_CLICK,
        {
          integrations: { All: true },
        },
      ),
    );
    dispatch(
      redirectTo('/educational-assistance/course-request', {
        courseId: v4(),
      }),
    );
  }, [dispatch, serviceType]);

  return (
    <TuitionReimbursementLandingPage>
      <Container>
        <TitleContent>
          <ContentLeft>
            <SvgImage SvgComponent={TuitionReimbursement}></SvgImage>
          </ContentLeft>
          <ContentRight>
            <TuitionReimbursementInstructions />
          </ContentRight>
        </TitleContent>
        <CardContent>
          <ButtonContainer>
            <Button width={240} onClick={handleGetStarted}>
              Get Started
            </Button>
          </ButtonContainer>
        </CardContent>
      </Container>
    </TuitionReimbursementLandingPage>
  );
};

const Container = styled.div`
  @media (min-width: ${MobileSize}) {
    padding: 10px;
  }
`;

const TitleContent = styled(FlexRow)`
  display: flex;
  text-align: left;
  justify-content: center;

  flex-direction: column;
  @media (min-width: ${MobileSize}) {
    flex-direction: row;
  }
`;

const ContentLeft = styled.div`
  align-self: center;

  svg {
    width: 100%;
  }
  margin-bottom: 24px;
  @media (min-width: ${MobileSize}) {
    margin-right: 64px;
    margin-bottom: 0;
  }
`;

const ContentRight = styled.div`
  max-width: 430px;
  & > * {
    margin-top: 16px;
  }
`;

const CardContent = styled(FlexRow)`
  display: flex;
`;

const RoundedOrderedList = styled.ol`
  counter-reset: li;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
`;
const OrderedListItem = styled.li`
  position: relative;
  display: block;
  padding: 0 22px 0 40px;
  margin-top: 10px;
`;

const OrderedListTitle = styled(Text)`
  &:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: 0px;
    color: ${hexToRgba(COLORS.darkGreen, 0.7)};
    background: ${hexToRgba(COLORS.jade, 0.15)};
    height: 32px;
    width: 32px;
    line-height: 1.9;
    text-align: center;
    font-weight: bold;
    border-radius: 32px;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

export default StartPage;
