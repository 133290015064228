import { isNil } from 'lodash';

import {
  DELETE_FILE,
  FINISH_UPLOAD,
  FETCH_UPLOAD_URL,
  FETCH_UPLOAD_URL_V4,
  NETWORK_FAILURE,
  NETWORK_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  FETCH_FILE_COLLECTION,
  FETCH_UPLOAD_URL_FORM,
  FINISH_UPLOAD_FORM,
  FINISH_UPLOAD_V4,
  UPLOAD_FILE_V4,
  FETCH_FILE_DOWNLOAD_LINK,
} from 'constants/actionTypes';
import { apiRequest, s3Request } from 'redux/actions/api.actions';
import {
  finishUpload,
  fetchUploadFileUrl,
  setFileInfo,
  uploadError,
  uploadFileToS3,
  uploadSuccess,
  setFileCollections,
  fetchUploadFileUrlForm,
  finishUploadForm,
  fetchUploadFileUrlV4,
  finishUploadV4,
} from 'redux/actions/fileCollections.actions';
import { setS3UploadProgress } from 'redux/actions/ui.actions';
import handleSideEffects from 'redux/handleSideEffects';

const fetchUploadUrlRoute = '/v1/files/s3key';
const finishUploadRoute = '/v1/files/upload';
const fetchUploadUrlRouteV4 = '/v4/files/s3key';
const finishUploadRouteV4 = '/v4/files/upload';

const fetchUploadUrlFormRoute = '/v3/form/files/s3key';
const finishUploadFormRoute = '/v3/form/files/upload';

const fileCollectionsMiddleware = handleSideEffects({
  [UPLOAD_FILE]: (
    dispatch,
    { payload: { collection, file, formId }, meta: { onSuccess } },
  ) => {
    dispatch(setS3UploadProgress({ [file.name]: 0 }));

    if (isNil(formId)) {
      dispatch(
        fetchUploadFileUrl(
          {
            collection,
            file,
          },
          { onSuccess },
        ),
      );
    } else {
      dispatch(
        fetchUploadFileUrlForm({
          collection,
          file,
          formId,
        }),
      );
    }

    dispatch(
      setFileInfo({
        collection,
        fileInfo: {
          name: file.name,
        },
      }),
    );
  },

  [UPLOAD_FILE_V4]: (
    dispatch,
    { payload: { collection, file, formId = null }, meta: { onSuccess } },
  ) => {
    dispatch(setS3UploadProgress({ [file.name]: 0 }));

    dispatch(
      fetchUploadFileUrlV4(
        {
          collection,
          file,
          formId,
        },
        { onSuccess },
      ),
    );

    dispatch(
      setFileInfo({
        collection,
        fileInfo: {
          name: file.name,
        },
      }),
    );
  },

  [DELETE_FILE]: (dispatch, { payload: { collection, fileId } }) => {
    dispatch(
      apiRequest({
        url: `/upload`,
        method: 'DELETE',
        body: { fileId },
        meta: {
          collection,
        },
        fromAction: DELETE_FILE,
      }),
    );
  },

  [FETCH_UPLOAD_URL]: (
    dispatch,
    { payload: { collection, file }, meta: { onSuccess } },
  ) => {
    dispatch(
      apiRequest({
        url: fetchUploadUrlRoute,
        method: 'GET',
        params: {
          collectionType: collection,
          fileName: file.name,
        },
        fromAction: FETCH_UPLOAD_URL,
        label: 'fetchUploadFileUrl',
        meta: {
          uploadPayload: {
            file,
            collection,
          },
          onSuccess,
        },
      }),
    );
  },

  [FETCH_UPLOAD_URL_V4]: (
    dispatch,
    { payload: { collection, file, formId = null }, meta: { onSuccess } },
  ) => {
    dispatch(
      apiRequest({
        url: fetchUploadUrlRouteV4,
        method: 'GET',
        params: {
          collectionType: collection,
          fileName: file.name,
          formId,
        },
        fromAction: FETCH_UPLOAD_URL_V4,
        label: 'fetchUploadFileUrlv4',
        meta: {
          uploadPayload: {
            file,
            collection,
            formId,
          },
          onSuccess,
        },
      }),
    );
  },

  [FETCH_UPLOAD_URL_FORM]: (
    dispatch,
    { payload: { collection, file, formId } },
  ) => {
    dispatch(
      apiRequest({
        url: fetchUploadUrlFormRoute,
        method: 'GET',
        params: {
          collection,
          fileName: file.name,
          formId,
        },
        fromAction: FETCH_UPLOAD_URL,
        meta: {
          uploadPayload: {
            file,
            collection,
            formId,
          },
        },
      }),
    );
  },

  [UPLOAD_FILE_S3]: (dispatch, { payload, meta: { onSuccess } }) => {
    const { file, collection, url, formId, s3Key } = payload;

    dispatch(
      s3Request({
        url,
        method: 'PUT',
        body: file,
        fromAction: UPLOAD_FILE_S3,
        label: 'uploadFileToS3',
        meta: {
          uploadPayload: {
            collection,
            fileName: file.name,
            formId,
            s3Key,
          },
          onSuccess,
        },
      }),
    );
  },

  [FINISH_UPLOAD]: (
    dispatch,
    { payload: { collection, fileName }, meta: { onSuccess } },
  ) => {
    dispatch(
      apiRequest({
        url: finishUploadRoute,
        method: 'POST',
        params: {
          fileName,
          collectionType: collection,
        },
        meta: {
          uploadPayload: {
            collection,
            fileName,
          },
          onSuccess,
        },
        fromAction: FINISH_UPLOAD,
      }),
    );
  },

  [FINISH_UPLOAD_V4]: (
    dispatch,
    {
      payload: { collection, fileName, s3Key, formId = null },
      meta: { onSuccess },
    },
  ) => {
    dispatch(
      apiRequest({
        url: finishUploadRouteV4,
        method: 'POST',
        params: {
          fileName,
          collectionType: collection,
          s3Key,
          formId,
        },
        meta: {
          uploadPayload: {
            collection,
            fileName,
            formId,
          },
          onSuccess,
        },
        fromAction: FINISH_UPLOAD_V4,
      }),
    );
  },

  [FINISH_UPLOAD_FORM]: (
    dispatch,
    { payload: { collection, fileName, formId } },
  ) => {
    dispatch(
      apiRequest({
        url: finishUploadFormRoute,
        method: 'POST',
        params: {
          collection,
          fileName,
          formId,
        },
        meta: {
          uploadPayload: {
            collection,
            fileName,
            formId,
          },
        },
        fromAction: FINISH_UPLOAD,
      }),
    );
  },

  [FETCH_FILE_COLLECTION]: (dispatch, { payload: { collection } }) => {
    dispatch(
      apiRequest({
        url: `/v1/files/${collection}`,
        method: 'GET',
        fromAction: FETCH_FILE_COLLECTION,
        label: 'fetchFileCollection',
        meta: {
          collection,
        },
      }),
    );
  },

  [FETCH_FILE_DOWNLOAD_LINK]: (dispatch, { payload: { fileId }, meta }) => {
    dispatch(
      apiRequest({
        url: `/v1/files/download/${fileId}`,
        method: 'GET',
        fromAction: FETCH_FILE_DOWNLOAD_LINK,
        label: 'fetchFileDownloadLink',
        meta,
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    const { file, collection, fileName, formId, s3Key } =
      meta.uploadPayload || {};

    if (
      meta.fromAction === FETCH_UPLOAD_URL ||
      meta.fromAction === FETCH_UPLOAD_URL_FORM
    ) {
      dispatch(
        uploadFileToS3(
          {
            file,
            url: payload.response,
            collection,
            formId,
          },
          {
            onSuccess: meta.onSuccess,
          },
        ),
      );
    }

    if (meta.fromAction === FETCH_UPLOAD_URL_V4) {
      dispatch(
        uploadFileToS3(
          {
            file,
            url: payload.response.signedUrl,
            collection,
            formId,
            s3Key: payload.response.s3Key,
          },
          {
            onSuccess: meta.onSuccess,
          },
        ),
      );
    }

    if (meta.fromAction === UPLOAD_FILE_S3) {
      if (s3Key) {
        dispatch(
          finishUploadV4(
            {
              collection,
              fileName,
              s3Key,
              formId,
            },
            { onSuccess: meta.onSuccess },
          ),
        );
      } else if (isNil(formId)) {
        dispatch(
          finishUpload(
            {
              collection,
              fileName,
              formId,
            },
            { onSuccess: meta.onSuccess },
          ),
        );
      } else {
        dispatch(
          finishUploadForm({
            collection,
            fileName,
            formId,
          }),
        );
      }
    }

    if (
      meta.fromAction === FINISH_UPLOAD ||
      meta.fromAction === FINISH_UPLOAD_V4
    ) {
      dispatch(
        uploadSuccess({
          collection,
          fileInfo: {
            name: fileName,
            id: payload.response.fileId,
          },
        }),
      );
      if (meta.onSuccess) {
        meta.onSuccess({ fileId: payload.response.fileId });
      }
    }

    if (meta.fromAction === FETCH_FILE_COLLECTION) {
      dispatch(
        setFileCollections({
          fileCollections: {
            [meta.collection]: payload.response.data.map(data => ({
              ...data,
              uploaded: true,
            })),
          },
        }),
      );
    }

    if (meta.fromAction === FETCH_FILE_DOWNLOAD_LINK) {
      if (meta.onSuccess) {
        meta.onSuccess(payload.response);
      }
    }
  },

  [NETWORK_FAILURE]: (dispatch, { payload, meta }) => {
    const { file, collection, fileName } = meta.uploadPayload || {};
    const statusCode = payload?.error?.response?.status;

    if (
      meta.fromAction === FETCH_UPLOAD_URL ||
      meta.fromAction === FETCH_UPLOAD_URL_FORM ||
      meta.fromAction === UPLOAD_FILE_S3 ||
      meta.fromAction === FINISH_UPLOAD ||
      meta.fromAction === FINISH_UPLOAD_FORM ||
      meta.fromAction === UPLOAD_FILE_V4 ||
      meta.fromAction === FETCH_UPLOAD_URL_V4 ||
      meta.fromAction === FINISH_UPLOAD_V4
    ) {
      const fileNameFallback = fileName || file.name;

      const errorDefaultOptions = {
        collection,
        fileInfo: { name: fileNameFallback },
      };

      const errorOptions =
        statusCode === 409
          ? {
              ...errorDefaultOptions,
              errorMessage: `Failed to upload file. A file named ${fileNameFallback} has already been uploaded. Please change the file name and try again.`,
            }
          : errorDefaultOptions;

      dispatch(uploadError(errorOptions));
    }

    if (meta.fromAction === FETCH_FILE_DOWNLOAD_LINK) {
      meta.onFailure();
    }
  },
});

export default fileCollectionsMiddleware;
