import {
  DELETE_FILE,
  FINISH_UPLOAD,
  FETCH_UPLOAD_URL,
  REMOVE_FILES_WITH_ERROR,
  SET_FILE,
  SET_FILE_COLLECTIONS,
  CLEAR_FILE_COLLECTIONS,
  UPLOAD_ERROR,
  UPLOAD_FILE,
  UPLOAD_FILE_S3,
  UPLOAD_SUCCESS,
  FETCH_FILE_COLLECTION,
  FINISH_UPLOAD_FORM,
  FETCH_UPLOAD_URL_FORM,
  REMOVE_FILES_WITH_COLLECTION,
  REMOVE_SINGLE_FILE_WITH_ERROR,
  UPLOAD_FILE_V4,
  FETCH_UPLOAD_URL_V4,
  FINISH_UPLOAD_V4,
  FETCH_FILE_DOWNLOAD_LINK,
} from 'constants/actionTypes';

export const uploadFile = (
  { collection, file, formId },
  { onSuccess } = {},
) => ({
  type: UPLOAD_FILE,
  payload: {
    collection,
    file,
    formId,
  },
  meta: { onSuccess },
});

export const uploadFileV4 = (
  { collection, file, formId },
  { onSuccess } = {},
) => ({
  type: UPLOAD_FILE_V4,
  payload: {
    collection,
    file,
    formId,
  },
  meta: { onSuccess },
});

export const deleteFile = ({ collection, fileId }) => ({
  type: DELETE_FILE,
  payload: { collection, fileId },
});

export const uploadFileToS3 = (
  { collection, file, url, formId, s3Key },
  { onSuccess },
) => ({
  type: UPLOAD_FILE_S3,
  payload: {
    collection,
    file,
    url,
    formId,
    s3Key,
  },
  meta: { onSuccess },
});

export const fetchUploadFileUrl = (
  { collection, file },
  { onSuccess } = {},
) => ({
  type: FETCH_UPLOAD_URL,
  payload: {
    collection,
    file,
  },
  meta: { onSuccess },
});

export const fetchUploadFileUrlV4 = (
  { collection, file, formId },
  { onSuccess } = {},
) => ({
  type: FETCH_UPLOAD_URL_V4,
  payload: {
    collection,
    file,
    formId,
  },
  meta: { onSuccess },
});

export const finishUpload = ({ collection, fileName }, { onSuccess } = {}) => ({
  type: FINISH_UPLOAD,
  payload: { collection, fileName },
  meta: { onSuccess },
});

export const finishUploadV4 = (
  { collection, fileName, s3Key, formId },
  { onSuccess } = {},
) => ({
  type: FINISH_UPLOAD_V4,
  payload: { collection, fileName, s3Key, formId },
  meta: { onSuccess },
});

export const fetchUploadFileUrlForm = ({ collection, file, formId }) => ({
  type: FETCH_UPLOAD_URL_FORM,
  payload: {
    collection,
    file,
    formId,
  },
});

export const finishUploadForm = ({ collection, fileName, formId }) => ({
  type: FINISH_UPLOAD_FORM,
  payload: { fileName, collection, formId },
});

export const uploadError = ({ collection, fileInfo, errorMessage }) => ({
  type: UPLOAD_ERROR,
  payload: {
    collection,
    fileInfo,
    errorMessage,
  },
});

export const uploadSuccess = ({ collection, fileInfo }) => ({
  type: UPLOAD_SUCCESS,
  payload: {
    collection,
    fileInfo,
  },
});

export const setFileInfo = ({ collection, fileInfo }) => ({
  type: SET_FILE,
  payload: {
    collection,
    fileInfo,
  },
});

export const setFileCollections = ({ fileCollections }) => ({
  type: SET_FILE_COLLECTIONS,
  payload: { fileCollections },
});

export const clearFileCollections = () => ({
  type: CLEAR_FILE_COLLECTIONS,
});

export const clearFilesErrors = () => ({
  type: REMOVE_FILES_WITH_ERROR,
});

export const removeFileWithError = ({ collection, fileName }) => ({
  type: REMOVE_SINGLE_FILE_WITH_ERROR,
  payload: { collection, fileName },
});

export const clearFilesFromCollection = ({ collection }) => ({
  type: REMOVE_FILES_WITH_COLLECTION,
  payload: { collection },
});

export const fetchFileCollection = ({ collection }) => ({
  type: FETCH_FILE_COLLECTION,
  payload: { collection },
});

export const fetchFileDownloadLink = ({ fileId }, { onSuccess } = {}) => ({
  type: FETCH_FILE_DOWNLOAD_LINK,
  payload: { fileId },
  meta: { onSuccess },
});
