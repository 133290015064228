import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  Header,
  GraduationCap,
  PiggyBank3,
  COLORS,
  ChartLineUp,
  OpenBookOutline,
  MobileSize,
  SlcIcon,
  EmergencyAlarm,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';
import {
  getIsCollegeSavingsPlannerToolEnabled,
  getIsRetirementMatchV1Enabled,
  getIsRetirementMatchDemo,
  getIsTuitionReimbursementEnabled,
  getIsHideRetirementMatchSideBarEnabled,
  getIsContributionsEnabled,
  getIsEmergencySavingsDemoEnabled,
  getIsEmergencySavingsV1Enabled,
} from 'redux/selectors/flags.selectors';
import {
  getIsTuitionAssistance,
  getTuitionReimbursementServiceType,
} from 'redux/selectors/tuitionReimbursement.selectors';
import { getEducationAssistanceLabel } from 'utils/tuitionReimbursement';

import WelcomeCard from './WelcomeCard';

const iconColor = COLORS.lightBlue;

const MultiToolWelcomeContent = ({
  proceedToRepayment,
  proceedToCSP,
  proceedToRetirement,
  proceedToTuitionReimbursement,
  proceedToStudentLoanContribution,
  proceedToEmergencySavings,
  showCollegeSavingsPlanner,
  showRetirementMatchV1,
  showRetirementMatchDemo,
  hideRetirementMatchSidebar,
  showTuitionReimbursement,
  showSLC,
  showEmergencySavings,
  isTuitionAssistance,
  tuitionReimbursementServiceType,
}) => {
  const showRetirementMatch =
    (showRetirementMatchV1 || showRetirementMatchDemo) &&
    !hideRetirementMatchSidebar;

  return (
    <Container>
      <Header h1 center>
        Welcome to Summer!
      </Header>
      <Text large bold center>
        How can we help?
      </Text>
      <Subtext>
        <Text center paragraph>
          Let us know which option best describes your situation. You can come
          back to explore our other offerings at any time.
        </Text>
      </Subtext>
      <GridContainer>
        <WelcomeCard
          testId="managing-student-debt-card"
          headerText="Managing Student Debt"
          contentText={`We\u2019ll help you find the best repayment and forgiveness options for your
      student\u00A0loans.`}
          icon={GraduationCap}
          iconStroke={iconColor}
          onClick={proceedToRepayment}
        />

        {showCollegeSavingsPlanner && (
          <WelcomeCard
            testId="paying-for-college-card"
            headerText="Paying for College"
            contentText={`We\u2019ll help you plan for college expenses for yourself or
    your loved\u00A0ones.`}
            icon={PiggyBank3}
            iconFill={iconColor}
            onClick={proceedToCSP}
          />
        )}
        {showRetirementMatch && (
          <WelcomeCard
            testId="retirement-match-card"
            headerText="Student Loan Retirement Match"
            contentText={`Enroll so that your employer can match qualifying student loan
          payments into your retirement plan (up to allowable limits).`}
            icon={ChartLineUp}
            iconFill={iconColor}
            onClick={proceedToRetirement}
          />
        )}
        {showTuitionReimbursement && (
          <WelcomeCard
            testId="tuition-reimbursement-card"
            headerText={getEducationAssistanceLabel(
              tuitionReimbursementServiceType,
            )}
            contentText={
              isTuitionAssistance
                ? `We'll help you get assistance for education expenses from your employer.`
                : `We'll help your employer reimburse you for education expenses.`
            }
            icon={OpenBookOutline}
            iconFill={iconColor}
            onClick={proceedToTuitionReimbursement}
          />
        )}
        {showSLC && (
          <WelcomeCard
            testId="student-loan-contribution-card"
            headerText="Student Loan Contribution"
            contentText="Enroll in the program by simply syncing your student loan and your employer will make direct payments to your servicer and help pay down your balance faster."
            icon={SlcIcon}
            iconFill={iconColor}
            onClick={proceedToStudentLoanContribution}
          />
        )}
        {showEmergencySavings && (
          <WelcomeCard
            testId="emergency-savings-card"
            headerText="Emergency Savings"
            contentText="Calculate your target savings, find a high-yield savings account and track your savings progress."
            icon={EmergencyAlarm}
            iconFill={iconColor}
            onClick={proceedToEmergencySavings}
          />
        )}
      </GridContainer>
    </Container>
  );
};

const Subtext = styled.div`
  max-width: 600px;

  @media (max-width: ${MobileSize}) {
    margin: 0 12px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  h1 {
    margin-bottom: 36px;
  }

  & > .text {
    margin-bottom: 12px;
  }
`;

const GridContainer = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 340px 340px;
  grid-template-rows: 240px 240px;
  grid-gap: 28px;
  margin-bottom: 25px;

  @media (max-width: ${TabletSize}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    margin: 40px 12px;

    .icon {
      margin-top: 32px;
      :first-child {
        margin-top: 0;
      }
    }

    .text {
      padding: 0 32px;
    }
  }
`;

const mapStateToProps = state => ({
  showCollegeSavingsPlanner: getIsCollegeSavingsPlannerToolEnabled(state),
  showRetirementMatchV1: getIsRetirementMatchV1Enabled(state),
  showRetirementMatchDemo: getIsRetirementMatchDemo(state),
  hideRetirementMatchSidebar: getIsHideRetirementMatchSideBarEnabled(state),
  showTuitionReimbursement: getIsTuitionReimbursementEnabled(state),
  showSLC: getIsContributionsEnabled(state),
  showEmergencySavings:
    getIsEmergencySavingsDemoEnabled(state) ||
    getIsEmergencySavingsV1Enabled(state),
  isTuitionAssistance: getIsTuitionAssistance(state),
  tuitionReimbursementServiceType: getTuitionReimbursementServiceType(state),
});

export default connect(mapStateToProps)(MultiToolWelcomeContent);
