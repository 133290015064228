import { filter, findIndex, isEmpty, reject } from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { PLAID_LINK_CUSTOMIZATION_NAMES } from '@simplifidev/shared/dist/constants/plaidLink';

import { Header } from '@summer/ui-components';

import { ManageAllPlaidLoansButton } from 'components/common/SyncLoans/Buttons';
import LoanDetails from 'components/pages/loans/components/LoanDetails';
import LoansTable from 'components/pages/loans/components/LoansTable';
import {
  HeaderContainer,
  DesktopHeader,
  Left,
  Container,
} from 'components/pages/loans/components/LoansView';
import SummaryBar from 'components/pages/loans/components/SummaryBar';
import SyncCard from 'components/pages/loans/components/SyncCard';
import DefaultAlert from 'components/pages/loans/components/alerts/DefaultAlert';
import Page from 'components/shared/dashboard/Page';
import { usePlaid } from 'hooks/plaid';
import { fetchAllLoans } from 'redux/actions/loans.actions';
import { fetchRecommendations } from 'redux/actions/recEngine.actions';
import { getPlaidLoans } from 'redux/selectors/loans.selectors';
import { getUserHasCompletedOnboarding } from 'redux/selectors/onboarding.selectors';
import { getGetOutOfDefaultRecommendation } from 'redux/selectors/recEngine.selectors';
import {
  getNormalizedPlaidLoans,
  findActiveServicers,
  findInactiveServicers,
} from 'utils/loans';

const MyStudentLoansWithLoans = ({
  getOutOfDefaultRecommended,
  plaidLoans,
  userHasCompletedOnboarding,
}) => {
  const [loanDetailsOpen, setLoanDetailsOpen] = useState(false);
  const [loanIndex, setLoanIndex] = useState(false);

  const dispatch = useDispatch();

  useEffect(
    function loadInitialData() {
      if (userHasCompletedOnboarding) {
        dispatch(fetchRecommendations());
      }
    },
    [dispatch, userHasCompletedOnboarding],
  );

  const { open: openPlaid, isLoading } = usePlaid({
    linkCustomizationName: PLAID_LINK_CUSTOMIZATION_NAMES.loanSync,
    successAction: fetchAllLoans,
  });

  const federalPlaidLoans = filter(plaidLoans, { isFederal: true });
  const privatePlaidLoans = reject(plaidLoans, { isFederal: true });

  const allLoans = [...federalPlaidLoans, ...privatePlaidLoans];

  const openLoanDetails = loanId => {
    const loanIndex = findIndex(
      allLoans,
      loan => loan.id === loanId || loan.uuid === loanId,
    );
    setLoanIndex(loanIndex);
    setLoanDetailsOpen(true);
  };
  const closeLoanDetails = () => {
    setLoanDetailsOpen(false);
  };

  const allActiveServicers = findActiveServicers(allLoans);
  const allInactiveServicers = findInactiveServicers(allLoans);

  const columnsToShow = ['loanType', 'interestRate', 'currentBalance'];

  return (
    <>
      <Page isLoading={isLoading}>
        <Container>
          <HeaderContainer>
            <Left>
              <DesktopHeader>
                <Header h1>My Student Loans</Header>
              </DesktopHeader>
            </Left>
            <ManageAllPlaidLoansButton
              openPlaid={openPlaid}
              plaidLoans={plaidLoans}
            />
          </HeaderContainer>
          <SummaryBar normalizedLoans={allLoans} />

          {!isEmpty(federalPlaidLoans) && (
            <>
              <SectionHeader>
                <Header h2>Federal Loans</Header>
                {getOutOfDefaultRecommended && <DefaultAlert />}
              </SectionHeader>
              <LoansTable
                loans={federalPlaidLoans}
                allActiveServicers={allActiveServicers}
                activeServicersToDisplay={findActiveServicers(
                  federalPlaidLoans,
                )}
                allInactiveServicers={allInactiveServicers}
                inactiveServicersToDisplay={findInactiveServicers(
                  federalPlaidLoans,
                )}
                openLoanDetails={openLoanDetails}
                columnsToShow={columnsToShow}
              />
            </>
          )}

          {!isEmpty(privatePlaidLoans) && (
            <>
              <SectionHeader>
                <Header h2>Private Loans</Header>
              </SectionHeader>
              <LoansTable
                loans={privatePlaidLoans}
                allActiveServicers={allActiveServicers}
                activeServicersToDisplay={findActiveServicers(
                  privatePlaidLoans,
                )}
                allInactiveServicers={allInactiveServicers}
                inactiveServicersToDisplay={findInactiveServicers(
                  privatePlaidLoans,
                )}
                openLoanDetails={openLoanDetails}
                columnsToShow={columnsToShow}
              />
            </>
          )}

          {isEmpty(federalPlaidLoans) && (
            <SyncCard loanType="federal" onClick={openPlaid} />
          )}

          {isEmpty(privatePlaidLoans) && (
            <SyncCard loanType="private" onClick={openPlaid} />
          )}
        </Container>
      </Page>
      <LoanDetails
        loans={allLoans}
        initLoanIndex={loanIndex}
        isOpen={loanDetailsOpen}
        closeLoanDetails={closeLoanDetails}
      />
    </>
  );
};

const SectionHeader = styled.div`
  margin-bottom: 16px;

  // LastUpdatedFsaLoans
  & > .text {
    margin-top: 8px;
  }
`;

const mapStateToProps = state => ({
  plaidLoans: getNormalizedPlaidLoans(getPlaidLoans(state)),
  getOutOfDefaultRecommended: getGetOutOfDefaultRecommendation(state),
  userHasCompletedOnboarding: getUserHasCompletedOnboarding(state),
});

export default connect(mapStateToProps)(MyStudentLoansWithLoans);
