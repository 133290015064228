import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  COLORS,
  Checkbox,
  DateInput,
  Header,
  MoneyInput,
  Text,
  TextAreaInput,
  TextInput,
  SelectInput,
} from '@summer/ui-components/src';

import FormDrawersContainer from 'components/common/FormDrawersContainer';
import Content from 'components/layout/wizard/WizardContent';
import Footer from 'components/layout/wizard/WizardFooter';
import { Row, Spacer } from 'components/pages/idr/wizard/shared/styles';
import { InputWrapper } from 'components/pages/idr/wizard/steps/Submit/shared/Inputs';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { trackingEvents } from 'constants/trackingEvents';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { getTuitionReimbursementAttachments } from 'redux/selectors/fileCollections.selectors';
import {
  getIsTuitionAssistance,
  getTuitionReimbursementCategories,
} from 'redux/selectors/tuitionReimbursement.selectors';
import { getClientPolicyHash } from 'utils/tuitionReimbursement';

import {
  HeaderContainer,
  InfoContainer,
  InstructionsContainer,
} from '../../shared';
import TuitionFileUpload from '../../shared/TuitionFileUpload';

const CourseProviderLabel = () => (
  <Text>
    <Text bold as="span">
      Course Provider
    </Text>{' '}
    (name of college, institution or e-learning platform)
  </Text>
);

// TODO: [sc-41613] The eligibility link will be defined later and will vary based on partner
const CourseRequestForm = ({
  attachments,
  categories,
  formik: {
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  },
}) => {
  const policyLink = `/policies/latest/${getClientPolicyHash()}.pdf`;
  const dispatch = useDispatch();
  const isTuitionAssistance = useSelector(getIsTuitionAssistance);

  const policyLinkOnClick = React.useCallback(() => {
    dispatch(
      trackServerSide(
        trackingEvents.EDU_ASSIST[
          isTuitionAssistance ? 'assistance' : 'reimbursement'
        ].COURSE_REQUEST_ELIG_POLICY_CLICKED,
      ),
    );
  }, [dispatch, isTuitionAssistance]);

  const policyOnChange = React.useCallback(
    value => {
      handleChange(value);
      dispatch(
        trackServerSide(
          trackingEvents.EDU_ASSIST[
            isTuitionAssistance ? 'assistance' : 'reimbursement'
          ].COURSE_REQUEST_POLICY_ACKN_CLICKED,
        ),
      );
    },
    [dispatch, handleChange, isTuitionAssistance],
  );

  return (
    <div>
      <Row>
        <InputWrapper>
          <TextInput
            label={CourseProviderLabel()}
            placeholder="Enter the course provider"
            name="courseProvider"
            touched={touched.courseProvider}
            isSubmitting={isSubmitting}
            error={errors.courseProvider}
            value={values.courseProvider}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <TextInput
            label="Course Name"
            placeholder="Enter the course name"
            name="courseName"
            touched={touched.courseName}
            isSubmitting={isSubmitting}
            error={errors.courseName}
            value={values.courseName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </InputWrapper>
        <InputWrapper>
          <SelectInput
            label="Education Category"
            placeholder="Enter the education category"
            name="category"
            touched={touched.category}
            value={values.category}
            onChange={setFieldValue}
            error={errors.category}
            options={categories.map(category => ({
              label: category,
              value: category,
            }))}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <MoneyInput
            label="Course Cost"
            name="courseCost"
            touched={touched.courseCost}
            isSubmitting={isSubmitting}
            error={errors.courseCost}
            value={values.courseCost}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
          />
        </InputWrapper>
        <InputWrapper>
          <NoWrapRow>
            <DateInput
              label="Course Start Date"
              name="courseStartDate"
              touched={touched.courseStartDate}
              isSubmitting={isSubmitting}
              error={errors.courseStartDate}
              value={values.courseStartDate}
              onChange={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
            />
            <DateInput
              label="Course End Date"
              name="courseEndDate"
              touched={touched.courseEndDate}
              isSubmitting={isSubmitting}
              error={errors.courseEndDate}
              value={values.courseEndDate}
              onChange={setFieldValue}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
            />
          </NoWrapRow>
          <Spacer />
        </InputWrapper>
      </Row>
      <Divider />
      <Row>
        <InputWrapper>
          <Label bold>Attachments (required)</Label>
          <InstructionsContainer>
            <Text paragraph>
              Please upload any other information about the course per your
              employer&lsquo;s requirements. For example, a syllabus or your
              school&lsquo;s course schedule.
            </Text>
          </InstructionsContainer>
        </InputWrapper>
        <InputWrapper>
          <UploadContainer>
            <TuitionFileUpload
              fileCollection={attachments}
              collection="tuitionReimbursementAttachment"
              name="attachmentFiles"
              value={values.attachmentFiles}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </UploadContainer>
        </InputWrapper>
      </Row>
      <Divider />
      <Row>
        <InputWrapper>
          <Label bold>Additional Information</Label>
          <InstructionsContainer>
            <Text paragraph>
              {isTuitionAssistance ? (
                <>
                  Please provide additional information that would be helpful
                  for getting approval. This includes <i>the department</i> you
                  work in, your <i>job title</i>, your <i>employee ID</i>, and a
                  brief description as to why this course would be helpful for
                  your professional development.
                </>
              ) : (
                <>
                  Please provide any other information about this course per
                  your employer&rsquo;s requirements. For example, a brief
                  description as to why this course would be helpful for your
                  professional development.
                </>
              )}
            </Text>
          </InstructionsContainer>
          <div>
            <TextAreaInput
              name="additionalInformation"
              height="95px"
              touched={touched.additionalInformation}
              isSubmitting={isSubmitting}
              error={errors.additionalInformation}
              value={values.additionalInformation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </InputWrapper>
      </Row>
      <Divider />
      <Row>
        <InputWrapper>
          <Label bold>Policy Acknowledgement</Label>
          <InstructionsContainer>
            <Checkbox
              name="isPolicyAccepted"
              label={
                <>
                  <Text paragraph>
                    I acknowledge that I have read and accept the terms of my
                    employer&rsquo;s{' '}
                    <Link href={policyLink} onClick={policyLinkOnClick}>
                      eligibility policy
                    </Link>
                    .
                  </Text>
                </>
              }
              onChange={policyOnChange}
              touched={touched.isPolicyAccepted}
              checked={values.isPolicyAccepted}
              isSubmitting={isSubmitting}
              error={errors.isPolicyAccepted}
              handleBlur={handleBlur}
            />
          </InstructionsContainer>
        </InputWrapper>
      </Row>
    </div>
  );
};

const CourseRequest = () => {
  const formik = useFormikContext();
  const { handleSubmit, isValid, isSubmitting } = formik;

  const tuitionReimbursementAttachments = useSelector(
    getTuitionReimbursementAttachments,
  );

  const tuitionReimbursementCategories = useSelector(
    getTuitionReimbursementCategories,
  );

  return (
    <Content>
      <form onSubmit={handleSubmit}>
        <FormDrawersContainer>
          <HeaderContainer>
            <Header as="h5">COURSE INFORMATION</Header>
          </HeaderContainer>
          <InfoContainer>
            <CourseRequestForm
              attachments={tuitionReimbursementAttachments}
              categories={tuitionReimbursementCategories}
              formik={formik}
            />
          </InfoContainer>
        </FormDrawersContainer>
      </form>
      <Footer
        useSubmitLabel
        isThisLastStep
        isNextFakeDisabled={!isValid}
        isNextLoading={isSubmitting}
        isBackDisabled={isSubmitting}
        isValid={isValid}
        handleSaveContinue={handleSubmit}
      />
    </Content>
  );
};

const NoWrapRow = styled(Row)`
  flex: 1;
  flex-wrap: nowrap;

  @media (max-width: ${MobileSize}) {
    gap: 24px;
  }
`;

const Label = styled(Text)`
  font-size: 18px;
`;

const Divider = styled.div`
  width: 100%;

  border: solid 1px ${COLORS.grey};

  margin-top: 8px;
  margin-bottom: 32px;
`;

const UploadContainer = styled.div`
  height: 100%;
`;

export default CourseRequest;
