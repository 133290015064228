import { get, isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FlexRow, TextInput } from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { getErrorMessage } from 'redux/selectors/ui.selectors';
import { AftDetails } from 'services/partner/aftOnboard';
import { RecordKeeperDetails } from 'services/partner/recordKeeperOnboard';
import { SlcDetails } from 'services/partner/slcOnboard';

const CreateAccountForm = ({
  errors,
  touched,
  values,
  isSubmitting,
  handleChange,
  handleBlur,
  emailHelpMessage,
  partner,
  title,
  hasSlc,
  isRecordKeeper,
  partnerData,
  usePartnerCode,
  usePartnerGuid,
  partnerGuidHelpText,
  globalErrorMessage,
  useCustomCode,
  customCodeLabel,
  customCodeName,
  customCodeHelpText,
}) => {
  const emailHelpText = partner === 'aft' ? '' : emailHelpMessage;
  const removeUserNameInputs = !(hasSlc || isRecordKeeper);

  return (
    <FormContainer>
      {hasSlc && <SlcDetails partnerTitle={title} />}
      {isRecordKeeper && <RecordKeeperDetails recordKeeperTitle={title} />}
      {removeUserNameInputs &&
        (partner !== 'aft' ? (
          <SplitRow>
            <Box>
              <TextInput
                label="First Name"
                name="firstName"
                error={errors.firstName}
                touched={touched.firstName}
                isSubmitting={isSubmitting}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="given-name"
              />
            </Box>
            <Box>
              <TextInput
                label="Last Name"
                name="lastName"
                error={errors.lastName}
                touched={touched.lastName}
                isSubmitting={isSubmitting}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="family-name"
              />
            </Box>
          </SplitRow>
        ) : (
          <AftDetails
            firstName={get(partnerData, 'standardizedName.firstName')}
            memberId={get(
              partnerData,
              'urlData.partnerIdentifierData.identifierValue',
            )}
            localId={get(
              partnerData,
              'urlData.partnerIdentifierData.identifierMetadata.localNumber',
            )}
          />
        ))}
      <Box>
        {!hasSlc && (
          <EmailBox>
            <TextInput
              label="Email Address"
              name="email"
              error={errors.email}
              value={values.email}
              touched={touched.email}
              isSubmitting={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              helpMessage={emailHelpText}
              inputMode="email"
              autoComplete="email"
            />
          </EmailBox>
        )}
        {/* Dummy input to disable new-password autocomplete */}
        <input
          type="password"
          name="dummy-password"
          autoComplete="new-password"
          style={{ display: 'none' }}
        />
        <TextInput
          label="Password"
          type="password"
          name="password"
          error={errors.password}
          value={values.password}
          touched={touched.password}
          isSubmitting={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          helpMessage="Minimum 12 characters, using letters, numbers, and special characters"
          autoComplete="new-password"
        />
        {useCustomCode && (
          <PassCodeBox>
            <TextInput
              label={customCodeLabel}
              name={customCodeName}
              type="text"
              error={errors[customCodeName]}
              value={values[customCodeName]}
              touched={touched[customCodeName]}
              isSubmitting={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              helpMessage={customCodeHelpText}
            />
          </PassCodeBox>
        )}
        {usePartnerCode && (
          <PassCodeBox>
            <TextInput
              label="Access Code"
              name="partnerCode"
              error={errors.partnerCode}
              value={values.partnerCode}
              touched={touched.partnerCode}
              isSubmitting={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              helpMessage="Please enter a valid access code to sign up as a Premium Summer partner"
            />
          </PassCodeBox>
        )}
        {usePartnerGuid && (
          <PassCodeBox>
            <TextInput
              label="Summer ID"
              name="partnerGuid"
              error={errors.partnerGuid}
              value={values.partnerGuid}
              touched={touched.partnerGuid}
              isSubmitting={isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              helpMessage={partnerGuidHelpText}
            />
          </PassCodeBox>
        )}
        {partner === 'aftconvention' && (
          <>
            <PassCodeBox>
              <TextInput
                label="AFT Local Number"
                name="localNumber"
                error={errors.localNumber}
                value={values.localNumber}
                touched={touched.localNumber}
                isSubmitting={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </PassCodeBox>
            <PassCodeBox>
              <TextInput
                label="AFT Member ID"
                name="memberId"
                error={errors.memberId}
                value={values.memberId}
                touched={touched.memberId}
                isSubmitting={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </PassCodeBox>
          </>
        )}
      </Box>
      {!isNil(globalErrorMessage) ? (
        <ErrorBox>
          <GlobalError label="createAccount" />
        </ErrorBox>
      ) : null}
    </FormContainer>
  );
};

const Box = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const ErrorBox = styled.div`
  min-height: 50px;
  width: 100%;
`;

const EmailBox = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const PassCodeBox = styled.div`
  margin-top: 16px;
`;

const SplitRow = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }

  & > * {
    :first-child {
      margin-right: 16px;
    }

    @media (max-width: ${TabletSize}) {
      :first-child {
        margin-right: 0;
      }
    }
  }
`;

const FormContainer = styled(FlexRow)`
  flex-wrap: wrap;
  max-width: 100%;
`;

const mapStateToProps = state => ({
  globalErrorMessage: getErrorMessage(state, 'createAccount'),
});

export default withRouter(connect(mapStateToProps)(CreateAccountForm));
