import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import { omit } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '@summer/ui-components/src';

import Platform from 'components/hoc/Platform';
import Container from 'components/layout/wizard/WizardContainer';
import WizardHeader from 'components/layout/wizard/WizardHeader';
import { trackingEvents } from 'constants/trackingEvents';
import { TuitionReimbursementWizardSteps } from 'constants/tuitionReimbursement';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { clearFilesFromCollection } from 'redux/actions/fileCollections.actions';
import { postTuitionReimbursementCourseRequest } from 'redux/actions/tuitionReimbursement.actions';
import { setTuitionReimbursementUi } from 'redux/actions/ui.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { courseRequestSchema } from 'schemas/tuitionReimbursement';
import {
  getClientPolicyHash,
  getEducationAssistanceLabel,
} from 'utils/tuitionReimbursement';

import CourseRequest from './steps/CourseRequest';

const initialValues = {
  courseName: '',
  courseProvider: '',
  courseCost: '',
  courseStartDate: '',
  courseEndDate: '',
  additionalInformation: '',
  attachmentFiles: [],
  category: '',
  isPolicyAccepted: false,
};

const TuitionReimbursementCourseRequestWizard = ({ isMobile }) => {
  const dispatch = useDispatch();
  const tuitionReimbursementServiceType = useSelector(
    getTuitionReimbursementServiceType,
  );
  const acceptedPolicy = getClientPolicyHash();

  const config = [
    {
      title: 'Tell us about your course',
      render: props => <CourseRequest {...props} />,
    },
  ];

  const { steps, headers, progress, navigation } = useWizard({
    config,
  });

  const getValidFiles = collection => collection.filter(file => !file?.error);

  const validate = values => {
    const valuesToCheck = {
      ...values,
      attachmentFiles: getValidFiles(values.attachmentFiles),
    };

    return courseRequestSchema
      .validate(valuesToCheck, { abortEarly: false })
      .then(() => ({}))
      .catch(validationError => {
        return validationError.inner.reduce((errorsToResolve, item) => {
          return {
            ...errorsToResolve,
            [item.path]: item.message,
          };
        }, {});
      });
  };

  const handleSave = (values, { setSubmitting }) => {
    dispatch(
      trackServerSide(
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .COURSE_REQUEST_SUBMIT,
        {
          integrations: { All: true },
        },
      ),
    );
    dispatch(
      postTuitionReimbursementCourseRequest(
        {
          ...omit(values, 'id'),
          attachmentFiles: getValidFiles(values.attachmentFiles).map(
            file => file.id,
          ),
          acceptedPolicy,
        },
        {
          onSuccess: () => {
            dispatch(
              setTuitionReimbursementUi({
                showCompleteModal: true,
                completedStep: TuitionReimbursementWizardSteps.COURSE_REQUEST,
              }),
            );
            dispatch(redirectTo('/educational-assistance'));
            dispatch(
              clearFilesFromCollection({
                collection: 'tuitionReimbursementAttachment',
              }),
            );
            setSubmitting(false);
          },
          onFailure: () => {
            setSubmitting(false);
          },
        },
      ),
    );
  };

  const handleClose = () => {
    dispatch(
      trackServerSide(
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .COURSE_REQUEST_CANCEL_CLICKED,
        {
          integrations: { All: true },
        },
      ),
    );
    dispatch(redirectTo('/educational-assistance'));
  };

  useEffect(() => {
    dispatch(
      trackServerSide(
        trackingEvents.EDU_ASSIST[tuitionReimbursementServiceType]
          .COURSE_REQUEST_FORM_VIEWED,
        {
          integrations: { All: true },
        },
      ),
    );
  }, [dispatch, tuitionReimbursementServiceType]);

  return (
    <Container>
      <WizardHeader
        handleClose={handleClose}
        handleHeaderClick={() => undefined}
        progress={progress.overallProgress}
        headers={headers}
        currentStep={navigation.currentStep}
      >
        <Header as={isMobile ? 'h4' : 'h3'}>
          {getEducationAssistanceLabel(tuitionReimbursementServiceType)}
        </Header>
      </WizardHeader>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
        validate={validate}
        validationSchema={courseRequestSchema}
      >
        <>{renderWizardSteps(steps)}</>
      </Formik>
    </Container>
  );
};

export default Platform(TuitionReimbursementCourseRequestWizard);
