import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '@summer/ui-components/src';

import Platform from 'components/hoc/Platform';
import Container from 'components/layout/wizard/WizardContainer';
import WizardHeader from 'components/layout/wizard/WizardHeader';
import { trackingEvents } from 'constants/trackingEvents';
import { TuitionReimbursementWizardSteps } from 'constants/tuitionReimbursement';
import { useWizard } from 'hooks/Wizard/useWizard';
import { renderWizardSteps } from 'hooks/Wizard/utils';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { clearFilesFromCollection } from 'redux/actions/fileCollections.actions';
import { patchTuitionReimbursement } from 'redux/actions/tuitionReimbursement.actions';
import { setTuitionReimbursementUi } from 'redux/actions/ui.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { courseCompletionSchema } from 'schemas/tuitionReimbursement';
import { getEducationAssistanceLabel } from 'utils/tuitionReimbursement';

import ProofOfCompletion from './steps/ProofOfCompletion';

const initialValues = {
  paymentProofFiles: [],
  completionProofFiles: [],
};

const TuitionReimbursementProofOfCompletionWizard = ({ isMobile, history }) => {
  const { courseId } = history.location.state;
  const dispatch = useDispatch();
  const serviceType = useSelector(getTuitionReimbursementServiceType);

  if (!courseId) {
    throw new Error('Course ID is required to access this page');
  }

  const config = [
    {
      title: 'Provide proof of completion and payment',
      render: props => <ProofOfCompletion {...props} />,
    },
  ];

  const { steps, headers, progress, navigation } = useWizard({
    config,
  });

  const getValidFiles = collection => collection.filter(file => !file?.error);

  const validate = values => {
    const valuesToCheck = {
      ...values,
      completionProofFiles: getValidFiles(values.completionProofFiles),
      paymentProofFiles: getValidFiles(values.paymentProofFiles),
    };

    return courseCompletionSchema
      .validate(valuesToCheck, { abortEarly: false })
      .then(() => ({}))
      .catch(validationError => {
        return validationError.inner.reduce((errorsToResolve, item) => {
          return {
            ...errorsToResolve,
            [item.path]: item.message,
          };
        }, {});
      });
  };

  const handleSave = (values, { setSubmitting }) => {
    dispatch(
      trackServerSide(trackingEvents.EDU_ASSIST[serviceType].ADD_PROOF_SUBMIT, {
        integrations: { All: true },
      }),
    );
    dispatch(
      patchTuitionReimbursement(
        courseId,
        {
          paymentProofFiles: values.paymentProofFiles.map(file => file.id),
          completionProofFiles: values.completionProofFiles.map(
            file => file.id,
          ),
        },
        {
          onSuccess: () => {
            dispatch(
              setTuitionReimbursementUi({
                showCompleteModal: true,
                completedStep:
                  TuitionReimbursementWizardSteps.PROOF_OF_COMPLETION,
              }),
            );
            dispatch(redirectTo('/educational-assistance'));
            dispatch(
              clearFilesFromCollection({
                collection: 'tuitionReimbursementCompletionProof',
              }),
            );
            dispatch(
              clearFilesFromCollection({
                collection: 'tuitionReimbursementPaymentProof',
              }),
            );
            setSubmitting(false);
          },
          onFailure: () => {
            setSubmitting(false);
          },
        },
      ),
    );
  };

  useEffect(() => {
    dispatch(
      trackServerSide(
        trackingEvents.EDU_ASSIST[serviceType].ADD_PROOF_FORM_VIEWED,
        {
          integrations: { All: true },
        },
      ),
    );
  }, [dispatch, serviceType]);

  return (
    <Container>
      <WizardHeader
        handleClose={() => dispatch(redirectTo('/educational-assistance'))}
        handleHeaderClick={() => undefined}
        progress={progress.overallProgress}
        headers={headers}
        currentStep={navigation.currentStep}
      >
        <Header as={isMobile ? 'h4' : 'h3'}>
          {getEducationAssistanceLabel(serviceType)}
        </Header>
      </WizardHeader>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
        validate={validate}
      >
        <>{renderWizardSteps(steps)}</>
      </Formik>
    </Container>
  );
};

export default Platform(TuitionReimbursementProofOfCompletionWizard);
