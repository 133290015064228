import { get } from 'lodash';

import { TuitionReimbursementServiceTypes } from 'constants/tuitionReimbursement';

export const getTuitionReimbursement = state =>
  get(state, 'tuitionReimbursement', {});

export const getTuitionReimbursementServiceType = state =>
  get(
    state,
    'tuitionReimbursement.partnerConfig.serviceType',
    TuitionReimbursementServiceTypes.REIMBURSEMENT,
  );

export const getTuitionReimbursementApplicationDetails = state =>
  get(state, 'tuitionReimbursement.applicationDetails', {});

export const getTuitionReimbursementCategories = state =>
  get(state, 'tuitionReimbursement.partnerConfig.categories', []);

export const getIsTuitionAssistance = state =>
  getTuitionReimbursementServiceType(state) ===
  TuitionReimbursementServiceTypes.ASSISTANCE;
