import { push as redirectTo } from 'connected-react-router';
import { map } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  FlexCenter,
  Header,
  SvgImage,
  Text,
} from '@summer/ui-components';

import ContactUs from 'components/shared/ContactUs';

const LoansInDefault = ({
  getOutOfDefaultBullets,
  ineligibleProgram,
  contactUsSection,
}) => {
  const dispatch = useDispatch();

  return (
    <Container direction="column">
      <img alt="Loans in default" src="/images/idr/loansInDefault.png" />
      <TextContainer>
        <Header center>Let’s get your loans out of default.</Header>
        <Text as="p" paragraph>
          Loans in default are not eligible for {ineligibleProgram}. Don’t
          worry—we’ll help you get them back on track.
        </Text>
        <ul>
          {map(getOutOfDefaultBullets, ({ image, text }) => (
            <ListItem key={text}>
              <ListIcon width={24} SvgComponent={image} />
              <Text paragraph>{text}</Text>
            </ListItem>
          ))}
        </ul>
      </TextContainer>
      <Button
        width={224}
        onClick={() => {
          dispatch(redirectTo('/default', { skipStartPage: true }));
        }}
      >
        Get Started
      </Button>
      {contactUsSection || <ContactUs />}
    </Container>
  );
};

const Container = styled(FlexCenter)`
  width: 100%;
  max-width: 520px;
  margin: auto;

  & > div {
    margin-bottom: 16px;
  }

  & > img {
    max-width: 100%;
  }

  & > button {
    margin-top: 16px;
  }
`;

const TextContainer = styled.div`
  padding: 0 8px;
  margin-top: 40px;

  & > h1 {
    margin-bottom: 32px;
  }

  & > p {
    max-width: 550px;
    margin: 0 auto 16px auto;
  }

  & > ul {
    list-style-type: none;
  }
`;

const ListIcon = styled(SvgImage)`
  margin-right: 10px;
  margin-bottom: auto;
`;

const ListItem = styled.li`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;

  ${ListIcon} {
    margin-top: 4px;
  }
`;

export default LoansInDefault;
