import { pick } from 'lodash';

import {
  BriefcaseAid,
  CreditCardCheck,
  MoneyHand,
  WalletPainted,
} from '@summer/ui-components';

const getOutOfDefaultBullets = {
  creditScore: {
    image: CreditCardCheck,
    text: 'Improve your credit score significantly',
  },
  goodStanding: {
    image: CreditCardCheck,
    text: 'Get your loans back in good standing which could increase your credit score',
  },
  idr: {
    image: WalletPainted,
    text: 'Lower your monthly payments through income-driven plans',
  },
  forgiveness: {
    image: BriefcaseAid,
    text: 'Make you eligible for loan forgiveness options and additional federal student aid',
  },
  garnishment: {
    image: MoneyHand,
    text: 'Stop involuntary collections of your wages, tax refunds, and federal  benefits',
  },
};

export const getOutOfDefaultBulletsDefault = pick(getOutOfDefaultBullets, [
  'creditScore',
  'idr',
  'forgiveness',
  'garnishment',
]);

export const getOutOfDefaultBulletsIdr = pick(getOutOfDefaultBullets, [
  'creditScore',
  'forgiveness',
  'garnishment',
]);

export const getOutOfDefaultBulletsPslf = pick(getOutOfDefaultBullets, [
  'goodStanding',
  'forgiveness',
  'garnishment',
]);
