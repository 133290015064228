import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Card } from '@summer/ui-components';

import HelpFooter from 'components/pages/tuitionReimbursement/dashboard/shared/helpFooter';
import LandingPage from 'components/shared/dashboard/LandingPage';
import { MobileSize } from 'constants/styleguide';
import { Apostrophes } from 'constants/text';
import {
  getIsTuitionAssistance,
  getTuitionReimbursementServiceType,
} from 'redux/selectors/tuitionReimbursement.selectors';
import { getEducationAssistanceLabel } from 'utils/tuitionReimbursement';

const TuitionReimbursementLandingPage = ({
  children,
  tuitionReimbursementServiceType,
  isTuitionAssistance,
}) => {
  return (
    <LandingPage
      headerTheme="jade"
      headerName={getEducationAssistanceLabel(tuitionReimbursementServiceType)}
      subHeader={`Receive financial support for approved education expenses. Review your employer${Apostrophes}s eligibility guide and apply through Summer!`}
    >
      <ContentContainer>
        <ContentWrapper>
          <CardWrapper>
            <Card>{children}</Card>
          </CardWrapper>
          <HelpFooter isTuitionAssistance={isTuitionAssistance} />
        </ContentWrapper>
      </ContentContainer>
    </LandingPage>
  );
};

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  margin: 16px 16px 36px;
  @media (min-width: ${MobileSize}) {
    margin-top: 48px;
  }

  max-width: 896px;
  //necessary to make card background opaque
  position: relative;
  z-index: 1;
`;

const CardWrapper = styled.div`
  margin-bottom: 36px;
  @media (min-width: ${MobileSize}) {
    margin-bottom: 72px;
  }
`;

const mapStateToProps = state => ({
  tuitionReimbursementServiceType: getTuitionReimbursementServiceType(state),
  isTuitionAssistance: getIsTuitionAssistance(state),
});

export default connect(mapStateToProps)(TuitionReimbursementLandingPage);
