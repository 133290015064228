import { Field } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@summer/ui-components/src';

import FilesUpload from 'components/shared/FilesUpload/FilesUpload';
import { trackingEvents } from 'constants/trackingEvents';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  clearFilesErrors,
  deleteFile,
  removeFileWithError,
  uploadFileV4,
} from 'redux/actions/fileCollections.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';

const TuitionFileUpload = ({
  fileCollection,
  collection,
  value,
  setFieldValue,
  setFieldTouched,
  name,
}) => {
  const dispatch = useDispatch();
  const serviceType = useSelector(getTuitionReimbursementServiceType);
  useEffect(() => {
    setFieldTouched(name, false);
  }, [name, setFieldTouched]);

  useEffect(() => {
    // only gets called once the update has been confirmed in redux
    setFieldValue(name, fileCollection);
    setFieldTouched(name, true);
  }, [setFieldValue, setFieldTouched, name, fileCollection]);

  const EAFileUploadEvents = {
    tuitionReimbursementCompletionProof: {
      uploadSuccess:
        trackingEvents.EDU_ASSIST[serviceType]
          .ADD_PROOF_COMPLETION_FILE_UPLOADED,
      clicked:
        trackingEvents.EDU_ASSIST[serviceType]
          .ADD_PROOF_COMPLETION_FILE_CLICKED,
    },
    tuitionReimbursementPaymentProof: {
      uploadSuccess:
        trackingEvents.EDU_ASSIST[serviceType].ADD_PROOF_RECEIPT_FILE_UPLOADED,
      clicked:
        trackingEvents.EDU_ASSIST[serviceType].ADD_PROOF_RECEIPT_FILE_CLICKED,
    },
    tuitionReimbursementAttachment: {
      uploadSuccess:
        trackingEvents.EDU_ASSIST[serviceType]
          .COURSE_REQUEST_UPLOAD_FILE_UPLOADED,
      clicked:
        trackingEvents.EDU_ASSIST[serviceType]
          .COURSE_REQUEST_UPLOAD_FILE_CLICKED,
    },
  };

  return (
    <Field name={name}>
      {({ meta }) => {
        return (
          <>
            <FilesUpload
              clearFilesErrors={clearFilesErrors}
              handleFileUpload={file => {
                dispatch(
                  uploadFileV4(
                    { collection, file },
                    {
                      onSuccess: () => {
                        dispatch(
                          trackServerSide(
                            EAFileUploadEvents[collection].uploadSuccess ??
                              null,
                            {
                              integrations: { All: true },
                            },
                          ),
                        );
                      },
                    },
                  ),
                );
              }}
              handleDeleteFile={fileId => {
                dispatch(deleteFile({ collection, fileId }));
              }}
              handleRemoveFileWithError={fileName => {
                dispatch(removeFileWithError({ collection, fileName }));
              }}
              onClick={() =>
                dispatch(
                  trackServerSide(
                    EAFileUploadEvents[collection].clicked ?? null,
                    {
                      integrations: { All: true },
                    },
                  ),
                )
              }
              fileList={value}
              collectionName={collection}
              dropZoneHeight="104px"
              dropZoneOnTop={true}
            />
            <Text size="small" color="error">
              {meta.touched && meta.error}
            </Text>
          </>
        );
      }}
    </Field>
  );
};

export default TuitionFileUpload;
