import { camelCase } from 'lodash';
import { useSelector } from 'react-redux';

import {
  EducationAssistanceLabels,
  eligibilityPolicyClientFileHash,
} from 'constants/tuitionReimbursement';
import { getUserPartnerKey } from 'redux/selectors/user.selectors';

export const getEducationAssistanceLabel = serviceType => {
  return (
    EducationAssistanceLabels[serviceType] || EducationAssistanceLabels.generic
  );
};

export const getClientPolicyHash = () => {
  const partnerKey = useSelector(getUserPartnerKey);
  const partnerCamelCase = camelCase(partnerKey);
  return eligibilityPolicyClientFileHash[partnerCamelCase];
};

export const getPolicyLink = () =>
  `/policies/latest/${getClientPolicyHash()}.pdf`;
