import React from 'react';
import styled from 'styled-components';

import { Alert, Strong, Text } from '@summer/ui-components';

const DefaultAlert = () => (
  <DefaultAlertContainer>
    <Alert
      theme="info"
      dismissable={false}
      showIcon={true}
      callToAction={{
        label: 'Get started',
        link: '/default',
        fullWidthMobileButton: true,
      }}
    >
      <Text small paragraph>
        It looks like <Strong>you have loans in default.</Strong> We can help
        with the next steps for getting your loans back into good standing
      </Text>
    </Alert>
  </DefaultAlertContainer>
);

const DefaultAlertContainer = styled.div`
  margin-top: 16px;
`;

export default DefaultAlert;
