import { push as redirectTo } from 'connected-react-router';
import { filter } from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Card, FlexRow, Header, Text } from '@summer/ui-components';

import RetirementMatchLandingPage from 'components/pages/retirementMatch/shared/retirementMatchLandingPage';
import FilesUpload from 'components/shared/FilesUpload/FilesUpload';
import { Link } from 'components/shared/typography';
import { rmDocumentHelpLink } from 'constants/externalLinks/support';
import { MobileSize } from 'constants/styleguide';
import {
  clearFilesErrors,
  uploadFileV4,
} from 'redux/actions/fileCollections.actions';
import { createRetirementMatchManualMatch } from 'redux/actions/retirementMatch.actions';
import { getRetirementMatchDocuments } from 'redux/selectors/fileCollections.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';

const collectionType = 'retirementMatchPaymentProof';

const FileUploadDescriptionCopy = ({ hasNoPlaidTransactions }) => {
  return (
    <Text paragraph size="small">
      {hasNoPlaidTransactions ? (
        <>
          It looks like we weren’t able to capture all of your payment data in
          the loan sync. We’re asking for additional payment information so that
          you can continue enrolling in the benefit. To proceed, we’ll need you
          to upload a document (or documents) from your loan servicer that shows
          all the payments you’ve made toward your loans in the last 3 months.
          Depending your servicer, you should be able to download an account
          payment history or your monthly statements.
        </>
      ) : (
        <>
          Upload a document (or documents) from your loan servicer that shows
          all the payments you&rsquo;ve made toward your loans so far this year.
        </>
      )}
    </Text>
  );
};

const DocumentRequirements = () => {
  return (
    <>
      <Text size="extraLarge" bold paragraph>
        Your document(s) need to show:
      </Text>
      <UnorderedList>
        <li>
          <Text paragraph color="dark">
            Your full name
          </Text>
        </li>
        <li>
          <Text paragraph color="dark">
            Your loan servicer&rsquo;s name
          </Text>
        </li>
        <li>
          <Text paragraph color="dark">
            Your payment history, including payment date and amount
          </Text>
        </li>
      </UnorderedList>

      <Text paragraph size="small">
        If you&rsquo;re unsure of where to begin, you should be able to find
        this information by logging into your online account on your loan
        servicer&rsquo;s website. You can also refer to our{' '}
        <Link href={rmDocumentHelpLink} inheritColor underline>
          Help Center
        </Link>{' '}
        for additional support.
      </Text>
    </>
  );
};

const RetirementMatchFileUpload = ({ uploadedDocuments, isLoading }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { hasNoPlaidTransactions } = location?.state || {};

  const handleContinue = React.useCallback(() => {
    const validUploadedDocuments = filter(
      uploadedDocuments,
      doc => !doc?.error,
    );

    const redirectOptions = validUploadedDocuments.length
      ? {
          origin: location?.pathname,
        }
      : {};

    dispatch(
      redirectTo('/retirement-match/dashboard/documents', redirectOptions),
    );
  }, [dispatch, location?.pathname, uploadedDocuments]);

  const onSuccess = ({ fileId }) =>
    dispatch(createRetirementMatchManualMatch({ fileId }));

  const handleFileUpload = file => {
    dispatch(
      uploadFileV4(
        { collection: collectionType, file, formId: null },
        { onSuccess },
      ),
    );
  };

  return (
    <RetirementMatchLandingPage>
      <Card>
        <Container>
          <PageHeader h2 bold>
            {hasNoPlaidTransactions
              ? 'We need some additional information'
              : 'Upload your payment documents'}
          </PageHeader>
          <FileUploadDescriptionCopy
            hasNoPlaidTransactions={hasNoPlaidTransactions}
          />
          <CardContent>
            <ContentLeft>
              <DocumentRequirements />
            </ContentLeft>
            <ContentRight>
              <FilesUpload
                clearFilesErrors={clearFilesErrors}
                handleFileUpload={handleFileUpload}
                collectionName={collectionType}
                fileList={uploadedDocuments}
                dropZoneOnTop={true}
              />
            </ContentRight>
          </CardContent>
          <ButtonContainer>
            <Button width={150} isLoading={isLoading} onClick={handleContinue}>
              Finish
            </Button>
          </ButtonContainer>
        </Container>
      </Card>
    </RetirementMatchLandingPage>
  );
};

const Container = styled.div`
  padding: 16px 16px 0px 16px;
`;

const PageHeader = styled(Header)`
  margin-bottom: 18px;

  @media (max-width: ${MobileSize}) {
    text-align: center;
  }
`;

const CardContent = styled(FlexRow)`
  display: flex;
  text-align: left;
  justify-content: center;

  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 26px;
  @media (min-width: ${MobileSize}) {
    flex-direction: row;
  }
`;

const ContentLeft = styled.div`
  align-self: stretch;

  margin-bottom: 24px;
  max-width: 340px;

  @media (min-width: ${MobileSize}) {
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

const ContentRight = styled.div`
  max-width: 600px;
  width: 100%;

  @media (min-width: ${MobileSize}) {
    height: 290px;
    max-height: 290px;
    overflow: hidden;
  }
`;

const UnorderedList = styled.ul`
  margin-left: 20px;
  margin-bottom: 20px;
`;

const ButtonContainer = styled(FlexRow)`
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const mapStateToProps = state => ({
  uploadedDocuments: getRetirementMatchDocuments(state),
  isLoading:
    isLoading(state, 'fetchUploadFileUrl') ||
    isLoading(state, 'uploadFileToS3') ||
    isLoading(state, 'createRetirementMatchManualMatch'),
});

export default connect(mapStateToProps)(RetirementMatchFileUpload);
